<ng-container *ngIf="dialogData.isSecurityKey" >
    <div class="modal">
        <div>
            <span class="modal-header">
           {{ "orderDetails_phyre_paymentDetails_info_qrCodeLabel" | locale }}
            </span>

            <div class="modal-qr-code">
                <img [src]="dialogData.qrCodeSrc">
            </div>

            <div class="bold">
                {{ "2FA_2FAStepOne_mobile_qrModal_qrKeyLabel" | locale }}
            </div>

            <div class="bolder secret-key-font">
                {{ dialogData.qrKey }}
            </div>

            <div class="button-container">
                <app-button class="secondary" (click)="close()">
                    {{ "2FA_2FAStepOne_mobile_qrModal_closeModalBtn" | locale }}
                </app-button>
                <app-button icon="copy" (click)="copyService.copyToClipboard(dialogData.qrKey)">
                    {{ "2FA_2FAStepOne_mobile_qrModal_copyBtn" | locale }}
                </app-button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogData.isReferralLink">
    <div [ngClass]="{ modal: true, tablet: viewSizeService.isTablet, mobile: viewSizeService.isMobile }">
        <div>
            <div class="close" (click)="close()">
                <app-icon icon="close"></app-icon>
            </div>

            <div class="top">
                    <span>
                        {{ 'referalLink_title' | locale }}
                    </span>

                <span>
                        {{ 'referalLink_description' | locale }}
                    </span>
            </div>

            <div class="qr-info">
                <div>
                        <span>
                            {{ "orderDetails_phyre_paymentDetails_info_qrCodeLabel" | locale }}
                        </span>
                    <img #qrcode src="{{dialogData.referenceQrCode}}">
                </div>
                <app-button (onclick)="downloadQRCode(qrcode.src)">Изтегли</app-button>
            </div>

            <div class="bottom">
                <div class="link">
                    <div>
                            <span>
                                {{ dialogData.referralLink }}
                            </span>

                        <span (click)="copyReferralLink()">
                                <app-icon icon="copy"></app-icon>
                            </span>
                    </div>

                    <div>
                        {{ 'referalLink_instruction' | locale }}
                    </div>
                </div>

                <div class="social-actions">
                    <div shareButton="facebook" [url]="dialogData.referralLink">
                        <app-icon icon="facebook"></app-icon>
                    </div>

                    <div (click)="shareOnInstagram()">
                        <app-icon icon="instagram"></app-icon>
                    </div>

                    <div shareButton="twitter" [url]="dialogData.referralLink">
                        <app-icon icon="twitter"></app-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
