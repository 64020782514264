import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalBannerComponent } from './global-banner.component';

@NgModule({
  declarations: [ GlobalBannerComponent ],
  imports: [ CommonModule ],
  exports: [ GlobalBannerComponent ]
})

export class GlobalBannerModule { }
