import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';

import { NotFound404Component } from './not-found-404.component';

@NgModule({
  declarations: [NotFound404Component],
  imports: [CommonModule, RouterModule, PipesModule],
  exports: [NotFound404Component],
})
export class NotFound404Module {}
