// This file is replaced by either prod or develop

import { endpoints } from './endpoints';

export const env = {
  production: false,
  apiUrl: (window as any).env?.apiUrl,
  invoiceUrl: (window as any).env?.invoiceUrl,
  priceFetcherApiUrl: (window as any).env?.priceFetcherApiUrl,
  phrase: {
    apiUrl: (window as any).env?.phrase.apiUrl,
    projectId: (window as any).env?.phrase.projectId,
    token: (window as any).env?.phrase.token,
  },
  gtmId: (window as any).env?.gtmId,
  endpoints,
};
