import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class XSSService {

  public cleanQueryParam(queryParam: string): string {
    return this.replace(queryParam);
  }

  public cleanBody<T>(body: any): T {
    for (const [key, value] of Object.entries(body)) {
      if (typeof value === 'string') {
        body[key] = this.replace(value);
      }
    }

    return body;
  }

  private replace(data: string): string {
    return data
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/=/g, '&equals;')
      .replace(/'/g, '&apos;')
      .replace(/"/g, '&quot;')
      .replace(/`/g, '&grave;');
  }

}
