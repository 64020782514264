import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GlobalBannerService {
  // Make sure new cookies to always have a unique name so that new banners don't get blocked by old cookies
  private bannerCookieName = '';

  constructor(private cookieService: CookieService) {}

  public doShowBanner(): boolean {
    if (this.cookieService.get(this.bannerCookieName)) {
      return false;
    }

    return true;
  }

  public hideBanner(): void {
    this.cookieService.set(this.bannerCookieName, 'true');
  }
}
