<div [ngClass]="{ container: true, tablet: viewSizeService.isTablet, mobile: viewSizeService.isMobile}">
    <div class="content">
        <div class="top">
            <span>{{ 'pageIsNotAvailable' | locale }}</span>

            <span>{{ 'notFound_notWorkingLink' | locale }}</span>
        </div>

        <div class="coin">
            <img src="assets/img/404/coin.png">
        </div>

        <div class="bottom">
            <div class="xchange">
                <span>xChange.bg</span>

                <span>Купи, продай, обмени</span>
            </div>

            <div class="links">
                <a [routerLink]="languageService.createLink('/')">{{ 'navigation_home' | locale }}</a>

                <a [routerLink]="languageService.createLink('/news')">{{ 'navigation_news' | locale }}</a>

                <a [routerLink]="languageService.createLink('/faq')">{{ 'navigation_faq' | locale }}</a>

                <a [routerLink]="languageService.createLink('/order')">{{ 'cryptoCurrencies_tradeBtn' | locale }}</a>
            </div>
        </div>
    </div>

    <div class="mask">
        <img *ngIf="viewSizeService.isMobilePortrait" [src]="themeService.imgs[themeService.currentTheme].maskMobile404">

        <img *ngIf="!viewSizeService.isMobilePortrait" [src]="themeService.imgs[themeService.currentTheme].mask404">
    </div>
</div>
