import { Injectable } from '@angular/core';

import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})

export class ScrollBlockService {

  private xOffset: number;
  private yOffset: number;

  constructor(
    private scrollService: ScrollService) { }

  public update(shouldActivateBlock: boolean): void {
    if (shouldActivateBlock) {
      const { x, y } = this.scrollService.getPosition();
      this.xOffset = x;
      this.yOffset = y;

      this.disablePerfectScroll();
    } else {
      this.enablePerfectScroll();
    }
  }

  private disablePerfectScroll(): void {
    this.scrollService.updateScrollSupress(true, true);

    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = -this.yOffset + 'px';
    body.style.height = 'calc(100% + ' + this.yOffset + 'px)';
  }

  private enablePerfectScroll(): void {
    this.scrollService.updateScrollSupress(false, false);

    const body = document.body;
    body.style.position = '';
    body.style.top = '0px';
    body.style.height = '100%';

    this.scrollService.setPosition(this.xOffset, this.yOffset);
  }

}
