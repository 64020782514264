import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private router: Router,
    private userService: UserService,
    private storageService: StorageService,
    private languageService: LanguageService,
  ) {}

  public logout(noRedirect = false): void {
    sessionStorage.clear();
    try {
      localStorage.clear();
    } catch (err) {}

    this.userService.clearUser();

    this.storageService.emitToken();
    if (!noRedirect) {
      this.router.navigate([this.languageService.createLink('/login')]);
    }
  }
}
