import { Injectable, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

export interface PerfectScrollConfig {
  suppressScrollX: boolean;
  suppressScrollY: boolean;
}

interface Position {
  x: number;
  y: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(private router: Router) {
    this.perfectScrollConfig = {
      suppressScrollX: false,
      suppressScrollY: false,
    };

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.setPosition(0, 0);
      }
    });
  }

  public perfectScrollConfig: PerfectScrollConfig;

  private perfectScrollbarElRef: PerfectScrollbarComponent;

  public setPerfectScrollbarElRef(perfectScrollbarElRef: PerfectScrollbarComponent): void {
    this.perfectScrollbarElRef = perfectScrollbarElRef;
  }

  public getPosition(): Position {
    return {
      x: Number(this.perfectScrollbarElRef.directiveRef?.position(true).x),
      y: Number(this.perfectScrollbarElRef.directiveRef?.position(true).y),
    };
  }

  public setPosition(x: number, y: number): void {
  }

  public updateScrollSupress(blockX: boolean, blockY: boolean): void {
    this.perfectScrollConfig.suppressScrollX = blockX;
    this.perfectScrollConfig.suppressScrollY = blockY;
  }

  public shouldFixSection(fixPoint: ElementRef<HTMLDivElement>, fixTarget: ElementRef<HTMLDivElement>): void {
    const viewHeight = window.innerHeight || document.documentElement.clientHeight;

    const fixPointBounding = fixPoint.nativeElement.getBoundingClientRect();

    const isTopOutOfView = fixPointBounding.top <= 0;
    const isBottomOutOfView = fixPointBounding.bottom >= viewHeight;

    const shouldFix = isTopOutOfView && isBottomOutOfView;

    const isFixedApplied = fixTarget.nativeElement.classList.contains('fixed');

    if (shouldFix && !isFixedApplied) {
      fixTarget.nativeElement.style.top = 0 + 'px';
      fixTarget.nativeElement.classList.add('fixed');
    } else if (!shouldFix && isFixedApplied) {
      if (isTopOutOfView) {
        fixTarget.nativeElement.style.top = fixPointBounding.height - viewHeight + 'px';
      }

      if (isBottomOutOfView) {
        fixTarget.nativeElement.style.top = 0 + 'px';
      }

      fixTarget.nativeElement.classList.remove('fixed');
    }
  }
}
