import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PipesModule } from './../../pipes/pipes.module';

import { FooterComponent } from './footer.component';
import { IconModule } from '../custom/icon/icon.module';
import { BoostPopupModule } from '../custom/boost-popup/boost-popup.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, IconModule, RouterModule, PipesModule, BoostPopupModule],
  exports: [FooterComponent],
})
export class FooterModule {}
