import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserService } from './../services/user.service';
import { StorageService } from './../services/storage.service';
import { LanguageService } from '../services/language.service';
import { LogoutService } from '../services/logout.service';

@Injectable()
export class UnauthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private storageService: StorageService,
    private languageService: LanguageService,
    private logoutService: LogoutService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const token = this.storageService.getItem('token');
    const user = this.userService.user;

    if (token || user) {
      if (state.url.startsWith(this.languageService.createLink('forgotten-password'))) {
        this.logoutService.logout(true);
        return true;
      }
      return this.router.parseUrl('/');
    }

    return true;
  }
}
