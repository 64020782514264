<app-navigation-desktop *ngIf="!viewSizeService.isMobile"
    [options]="navigationOptions"
    [isOrder]="isOrder"
    [isUserLogged]="isUserLogged"
    [isHomescreenOrBonusProgram]="isHomescreenOrBonusProgram">
</app-navigation-desktop>

<app-navigation-mobile *ngIf="viewSizeService.isMobile"
    [options]="navigationOptions"
    [isOrder]="isOrder"
    [isUserLogged]="isUserLogged"
    [isHomescreenOrBonusProgram]="isHomescreenOrBonusProgram">
</app-navigation-mobile>
