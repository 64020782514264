import { Component, ViewChild, AfterViewInit } from '@angular/core';

import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { SEOService } from './../../services/seo.service';
import { ThemeService } from './../../services/theme.service';
import { ScrollService } from './../../services/scroll.service';
import { ViewSizeService } from './../../services/view-size.service';
import { ScrollBlockService } from './../../services/scroll-block.service';
import { GlobalBannerService } from './../../services/global-banner.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.css'],
})
export class LayoutWrapperComponent implements AfterViewInit {
  @ViewChild(PerfectScrollbarComponent) perfectScrollbarElRef: PerfectScrollbarComponent;

  constructor(
    public scrollService: ScrollService,
    public cookieService: CookieService,
    public viewSizeService: ViewSizeService,
    public globalBannerService: GlobalBannerService,
  ) {}

  public ngAfterViewInit(): void {
    this.scrollService.setPerfectScrollbarElRef(this.perfectScrollbarElRef);
  }
}
