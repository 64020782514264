import { Injectable } from '@angular/core';

import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor() { }

  public user: User;

  public clearUser(): void {
    this.user = null as any;
  }

}
