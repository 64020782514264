import { Component, AfterViewInit, OnDestroy } from '@angular/core';

import { SEOService } from './../../services/seo.service';
import { ThemeService } from './../../services/theme.service';
import { LanguageService } from './../../services/language.service';
import { ViewSizeService } from './../../services/view-size.service';

@Component({
  selector: 'app-not-found-404',
  templateUrl: './not-found-404.component.html',
  styleUrls: ['./not-found-404.component.css']
})

export class NotFound404Component implements OnDestroy {

  constructor(
    public themeService: ThemeService,
    public languageService: LanguageService,
    public viewSizeService: ViewSizeService,
    private seoService: SEOService) { }

  public ngOnDestroy(): void {
    this.seoService.removePrerenderTags();
  }

}
