import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '../services/language.service';

@Pipe({ name: 'date' })
export class AppDatePipe extends DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string, private i18n: LanguageService) {
    super(locale);
  }
  /* tslint:disable:unified-signatures */
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: Date | string | number | null | undefined,
    format = 'mediumDate',
    timezone?: string,
    locale?: string,
  ): string | null {
    if (this.i18n.currentLanguage) {
      locale = locale || this.i18n.currentLanguage;
    }

    return super.transform(value, format, timezone, locale);
  }
}
