import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';

import { PipesModule } from './../../../pipes/pipes.module';
import { ButtonModule } from './../../custom/button/button.module';
import { ThemeButtonModule } from './../../custom/theme-button/theme-button.module';
import { LanguageSelectModule } from './../../custom/language-select/language-select.module';

import { NavigationDesktopComponent } from './navigation-desktop.component';

import { IconModule } from '../../custom/icon/icon.module';

@NgModule({
  declarations: [NavigationDesktopComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatSelectModule,
    PipesModule,
    ButtonModule,
    ThemeButtonModule,
    LanguageSelectModule,
    IconModule,
  ],
  exports: [NavigationDesktopComponent],
})
export class NavigationDesktopModule {}
