import { Pipe, PipeTransform } from '@angular/core';

import { LocalizationService } from './../services/localization.service';

@Pipe({
  name: 'locale'
})

export class LocalePipe implements PipeTransform {

  constructor(
    private localizationService: LocalizationService) { }

  public transform(key: string | undefined, ...args: (string | number)[]): string {
    if (key) {
      return this.localizationService.getString(key, ...args);
    }

    return '';
  }

}
