<div class="wrap" [ngClass]="{ container: true, tablet: viewSizeService.isTablet, mobile: viewSizeService.isMobile }">
    <div class="top">
        <div class="left">
            <div class="logo">
                <img [src]="themeService.imgs[themeService.currentTheme].logoIcon">
                <span>xChange.bg</span>
            </div>

            <div class="links">
                <span>{{ "footer_category1_title" | locale }}</span>
                <a [routerLink]="languageService.createLink('/about-us')">{{ "footer_category1_link_aboutUs" | locale }}</a>
                <a [routerLink]="languageService.createLink('/news')">{{ "footer_category1_link_news" | locale }}</a>
                <a [routerLink]="languageService.createLink('/faq')">{{ "footer_category1_link_faq" | locale }}</a>
                <a [routerLink]="languageService.createLink('/crypto-glossary')">{{ "footer_category1_link_cryptoGlossary" | locale }}</a>
            </div>

            <div class="links">
                <span>{{ "footer_category2_title" | locale }}</span>
                <a [routerLink]="languageService.createLink('/order')">{{ "footer_category2_link_order" | locale }}</a>
                <a [routerLink]="userService.user ? languageService.createLink('/profile/bonus-program') : languageService.createLink('/bonus-program')">{{ "footer_category2_link_bonusProgram" | locale }}</a>
                <a [routerLink]="languageService.createLink('/cryptocurrencies')">{{ "footer_category2_link_cryptoCurrencies" | locale }}</a>
                <a [routerLink]="languageService.createLink('/payment-methods')">{{ "footer_category2_link_paymentMethods" | locale }}</a>
            </div>

            <div class="links">
                <span>{{ "footer_category3_title" | locale }}</span>
                <a [routerLink]="languageService.createLink('/legal/terms-and-conditions')">{{ "footer_category3_link_termsAndConditions" | locale }}</a>
                <a [routerLink]="languageService.createLink('/legal/privacy-policy')">{{ "footer_category3_link_privacyPolicy" | locale }}</a>
                <a [routerLink]="languageService.createLink('/legal/terms-bonus-program')">{{ "footer_category3_link_termsBonusProgram" | locale }}</a>
                <a [routerLink]="languageService.createLink('/legal/aml-policy')">{{ "footer_category3_link_amlPolicy" | locale }}</a>
                <a href="#" (click)="cookiesPrefs($event)">{{ "footer_category3_link_cookiesPrefs" | locale }}</a>
            </div>
        </div>

        <div class="right">
            <div>
                <span>{{ "footer_info_phoneLabel" | locale }}</span>
                <span class="phone">+359 888 666 530</span>
                <span class="hours">10:00 - 18:00 {{ "footer_info_workingDaysLabel" | locale }}</span>
                <span>{{ "footer_info_followUsLabel" | locale }}</span>
                <div>
                    <a href="https://www.facebook.com/xchangebg" target="_blank">
                        <app-icon icon="facebook"></app-icon>
                    </a>
                    <a href="https://instagram.com/bg_xchange" target="_blank">
                        <app-icon icon="instagram"></app-icon>
                    </a>
                    <a href="https://twitter.com/xchangebg" target="_blank">
                        <app-icon icon="twitter"></app-icon>
                    </a>
                </div>
            </div>

            <div>
                <span>{{ "footer_info_emailLabel" | locale }}</span>
                <span class="email" (click)="openEmail()">support@xchange.bg</span>
                <span>{{ "footer_info_downloadAppLabel" | locale }}</span>


                <div class="apps">
                    <a href="https://play.google.com/store/apps/details?id=bg.xchange" target="_blank">
                        <app-icon icon="play-store"></app-icon>
                    </a>
                    <a href="https://apps.apple.com/bg/app/xchange-bg/id1543781417?itsct=apps_box_link&itscg=30200" target="_blank">
                        <app-icon icon="app-store"></app-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="line"></div>

    <div class="bottom">
        <span>{{ "footer_allRightsReserved_label" | locale }}</span>
        <span>{{ "footer_allRightsReserved_description" | locale }}</span>
    </div>

    <ng-container *ngIf="viewSizeService.isMobile">
        <div class="maskMobile"></div>
    </ng-container>

</div>
<app-boost-popup></app-boost-popup>
