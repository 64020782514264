import { Component } from '@angular/core';

import { UserService } from './../../services/user.service';
import { ThemeService } from './../../services/theme.service';
import { ViewSizeService } from './../../services/view-size.service';
import { EmailService } from './../../services/email.service';
import { LanguageService } from './../../services/language.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  constructor(
    public themeService: ThemeService,
    public viewSizeService: ViewSizeService,
    public userService: UserService,
    public languageService: LanguageService,
    private emailService: EmailService,
    private cookieService: CookieService,
  ) {}

  openEmail(): void {
    this.emailService.openEmail('support@xchange.bg');
  }

  public cookiesPrefs($event: PointerEvent | MouseEvent): void {
    $event.preventDefault();
    this.cookieService.set('cookiesPolicyManager', 'true', { expires: 365 });
  }
}
