import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Modules
import { NotFound404Module } from './components/not-found-404/not-found-404.module';
import { LayoutWrapperModule } from './components/layout-wrapper/layout-wrapper.module';

// Components
import { NotFound404Component } from './components/not-found-404/not-found-404.component';
import { LayoutWrapperComponent } from './components/layout-wrapper/layout-wrapper.component';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { UnauthGuard } from './guards/unauth.guard';
import { LanguageGuard } from './guards/language.guard';
import { LocalizationGuard } from './guards/localization.guard';

const routes: Routes = [
  // health check
  {
    path: 'health-check',
    loadChildren: () => import('./components/health-check/health-check.module').then((mod) => mod.HealthCheckModule),
  },

  // Common
  {
    path: '',
    component: LayoutWrapperComponent,
    canActivate: [LocalizationGuard, LanguageGuard],
    canActivateChild: [LocalizationGuard, LanguageGuard],
    children: [
      // Common
      {
        path: '',
        loadChildren: () => import('./components/home/home.module').then((mod) => mod.HomeModule),
      },
      {
        path: ':lang/faq',
        loadChildren: () => import('./components/faq/faq.module').then((mod) => mod.FAQModule),
      },
      {
        path: ':lang/legal',
        loadChildren: () => import('./components/legal/legal.module').then((mod) => mod.LegalModule),
      },
      {
        path: ':lang/about-us',
        loadChildren: () => import('./components/about-us/about-us.module').then((mod) => mod.AboutUsModule),
      },
      {
        path: ':lang/bonus-program',
        loadChildren: () =>
          import('./components/bonus-program/bonus-program.module').then((mod) => mod.BonusProgramModule),
      },
      {
        path: ':lang/news',
        loadChildren: () => import('./components/news/news.module').then((mod) => mod.NewsModule),
      },
      {
        path: ':lang/payment-methods',
        loadChildren: () =>
          import('./components/payment-methods/payment-methods.module').then((mod) => mod.PaymentMethodsModule),
      },
      {
        path: ':lang/crypto-glossary',
        loadChildren: () =>
          import('./components/crypto-glossary/crypto-glossary.module').then((mod) => mod.CryptoGlossaryModule),
      },
      {
        path: ':lang/boost',
        loadChildren: () => import('./components/boost/boost.module').then((mod) => mod.BoostModule),
      },

      // Auth
      {
        path: ':lang/login',
        loadChildren: () => import('./components/auth/login/login.module').then((mod) => mod.LoginModule),
        canActivate: [UnauthGuard],
      },
      {
        path: ':lang/register',
        loadChildren: () => import('./components/auth/register/register.module').then((mod) => mod.RegisterModule),
        canActivate: [UnauthGuard],
      },
      {
        path: ':lang/email-verification',
        loadChildren: () =>
          import('./components/auth/email-verification/email-verification.module').then(
            (mod) => mod.EmailVerificationModule,
          ),
        canActivate: [UnauthGuard],
      },
      {
        path: ':lang/forgotten-password',
        loadChildren: () =>
          import('./components/auth/forgotten-password/forgotten-password.module').then(
            (mod) => mod.ForgottenPasswordModule,
          ),
        canActivate: [UnauthGuard],
      },

      // Order
      {
        path: ':lang/order',
        loadChildren: () => import('./components/order/order.module').then((mod) => mod.OrderModule),
      },

      // Profile
      {
        path: ':lang/profile',
        loadChildren: () => import('./components/profile/profile.module').then((mod) => mod.ProfileModule),
        canActivate: [AuthGuard],
      },

      // Crypto currencies
      {
        path: ':lang/cryptocurrencies',
        loadChildren: () =>
          import('./components/crypto-currencies/crypto-currencies.module').then((mod) => mod.CryptoCurrenciesModule),
      },
      {
        path: ':lang/cryptocurrencies/:cryptoCurrency',
        loadChildren: () =>
          import('./components/crypto-currencies/crypto-currency-details/crypto-currency-details.module').then(
            (mod) => mod.CryptoCurrencyDetailsModule,
          ),
      },

      {
        path: ':lang',
        children: [
          {
            path: '',
            loadChildren: () => import('./components/home/home.module').then((mod) => mod.HomeModule),
          },
          {
            path: '**',
            component: NotFound404Component,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false, scrollPositionRestoration: 'enabled' }),
    NotFound404Module,
    LayoutWrapperModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
