import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavigationDesktopModule } from './desktop/navigation-desktop.module';
import { NavigationMobileModule } from './mobile/navigation-mobile.module';

import { NavigationComponent } from './navigation.component';

@NgModule({
  declarations: [ NavigationComponent ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationDesktopModule,
    NavigationMobileModule
  ],
  exports: [ NavigationComponent ]
})

export class NavigationModule { }
