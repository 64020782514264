import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// Providers
import { AppRouteReuseStrategy } from './providers/app-route-reuse-strategy';

// Modules
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { UnauthGuard } from './guards/unauth.guard';
import { LanguageGuard } from './guards/language.guard';
import { LocalizationGuard } from './guards/localization.guard';

import { CookieService } from 'ngx-cookie-service';
import { env } from 'src/environments/environment';
import { QrCodeDialogComponent } from './components/custom/qr-code-dialog/qr-code-dialog.component';
import { PipesModule } from './pipes/pipes.module';
import { ButtonModule } from './components/custom/button/button.module';
import { IconModule } from './components/custom/icon/icon.module';
import { ShareModule } from 'ngx-sharebuttons';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

@NgModule({
  declarations: [AppComponent, QrCodeDialogComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    GoogleTagManagerModule.forRoot({
      id: env.gtmId,
    }),
    PipesModule,
    ButtonModule,
    IconModule,
    ShareModule,
  ],
  providers: [
    CookieService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    AuthGuard,
    UnauthGuard,
    LanguageGuard,
    LocalizationGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
