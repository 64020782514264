import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRequestsService } from './../services/http/request/user-requests.service';

import { UserService } from './../services/user.service';
import { StorageService } from './../services/storage.service';
import { LogoutService } from './../services/logout.service';
import { LanguageService } from './../services/language.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userRequestsService: UserRequestsService,
    private userService: UserService,
    private storageService: StorageService,
    private logoutService: LogoutService,
    private languageService: LanguageService,
  ) {}

  public canActivate(): Observable<boolean> | UrlTree {
    const token = this.storageService.getItem('token');
    const user = this.userService.user;
    if (!token) {
      return this.router.parseUrl(this.languageService.createLink('login'));
    }

    if (token && !user) {
      return this.userRequestsService.getLoggedInUserInfo().pipe(
        map((response) => {
          switch (response.status) {
            case 200:
              this.userService.user = response.data;
              return true;
            default:
              this.logoutService.logout();
              return false;
          }
        }),
      );
    }

    return of(true);
  }
}
