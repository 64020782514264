import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { XSSService } from '../xss.service';
import { CustomResponse, HttpService } from './../http.service';

import { User } from './../../../models/user';
import { env } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserRequestsService {

  constructor(
    private xSSService: XSSService,
    private httpService: HttpService) { }

  // B -> body
  // R -> response

  public getLoggedInUserInfo(): Observable<CustomResponse<User>> {
    const url = env.apiUrl + env.endpoints.user.getLoggedInUserInfo;

    return this.httpService.get<User>(url);
  }

  public getWallets<R>(): Observable<CustomResponse<R>> {
    const url = env.apiUrl + env.endpoints.user.wallets;

    return this.httpService.get<R>(url);
  }

  public createWallet<B, R>(body: B): Observable<CustomResponse<R>> {
    const cleanBody = this.xSSService.cleanBody<B>(body);
    const url = env.apiUrl + env.endpoints.user.wallets;

    return this.httpService.post<B, R>(url, cleanBody);
  }

  public editWallet<B, R>(body: B, id: number): Observable<CustomResponse<R>> {
    const cleanBody = this.xSSService.cleanBody<B>(body);
    const url = `${env.apiUrl}${env.endpoints.user.wallets}/${id}`;

    return this.httpService.put<B, R>(url, cleanBody);
  }

  public deleteWallet<R>(id: number): Observable<CustomResponse<R>> {
    const url = `${env.apiUrl}${env.endpoints.user.wallets}/${id}`;

    return this.httpService.delete<R>(url);
  }

  public getBankAccounts<R>(): Observable<CustomResponse<R>> {
    const url = env.apiUrl + env.endpoints.user.bankAccounts;

    return this.httpService.get<R>(url);
  }

  public createBankAccount<B, R>(body: B): Observable<CustomResponse<R>> {
    const cleanBody = this.xSSService.cleanBody<B>(body);
    const url = env.apiUrl + env.endpoints.user.bankAccounts;

    return this.httpService.post<B, R>(url, cleanBody);
  }

  public editBankAccount<B, R>(body: B, id: number): Observable<CustomResponse<R>> {
    const cleanBody = this.xSSService.cleanBody<B>(body);
    const url = `${env.apiUrl}${env.endpoints.user.bankAccounts}/${id}`;

    return this.httpService.put<B, R>(url, cleanBody);
  }

  public deleteBankAccount<R>(id: number): Observable<CustomResponse<R>> {
    const url = `${env.apiUrl}${env.endpoints.user.bankAccounts}/${id}`;

    return this.httpService.delete<R>(url);
  }

  public getBonuses<R>(): Observable<CustomResponse<R>> {
    const url = env.apiUrl + env.endpoints.user.bonuses;

    return this.httpService.get<R>(url);
  }

}
