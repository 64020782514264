<div [ngClass]="{ app: true, tablet: viewSizeService.isTablet, mobile: viewSizeService.isMobile }">
    <app-navigation></app-navigation>

    <router-outlet></router-outlet>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>

<ng-container>
    <app-cookie-bar></app-cookie-bar>
</ng-container>

<!-- Uncomment when you want to show global banner -->
<!-- <ng-container *ngIf="globalBannerService.doShowBanner()">
     <app-global-banner></app-global-banner>
     </ng-container> -->
