import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CookieService } from 'ngx-cookie-service';
import { LocalizationService } from 'src/app/services/localization.service';

import { LanguageService } from './../../../services/language.service';
import { ViewSizeService } from './../../../services/view-size.service';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.css'],
})
export class CookieBarComponent implements OnInit {
  public prefsVisible = false;

  public consents = {
    necessary: true,
    marketing: false,
    thirdParty: false,
  };

  constructor(
    public viewSizeService: ViewSizeService,
    public languageService: LanguageService,
    public localizationService: LocalizationService,
    private cookieService: CookieService,
  ) {}

  public get showConsent(): boolean {
    if (navigator.cookieEnabled) {
      if (this.cookieService.get('cookiesPolicyManager') === 'true') {
        this.prefsVisible = true;
      }
      return !this.cookieService.get('cookiesPolicy') || this.cookieService.get('cookiesPolicyManager') === 'true';
    } else {
      return false;
    }
  }

  public acceptAll(): void {
    this.consents.marketing = true;
    this.consents.thirdParty = true;
    this.save();
  }

  public togglePrefs(): void {
    this.prefsVisible = !this.prefsVisible;
  }

  public done(): void {
    this.save();
  }

  public checkChange(item: 'marketing' | 'thirdParty', $event: MatCheckboxChange): void {
    this.consents[item] = $event.checked;
  }

  private save(): void {
    this.cookieService.set('cookiesPolicy', JSON.stringify(this.consents), { expires: 365 });
    this.cookieService.set('cookiesPolicyManager', 'false', { expires: 365 });
    this.prefsVisible = false;
  }

  public ngOnInit(): void {
    if (this.cookieService.get('cookiesPolicy')) {
      const cookieData = JSON.parse(this.cookieService.get('cookiesPolicy'));
      this.consents.marketing = cookieData.marketing;
      this.consents.thirdParty = cookieData.thirdParty;
    }
  }
}
