import { Injectable } from '@angular/core';

import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private localizationService: LocalizationService) { }

  public showNotification(text: string, imgSrc?: string): void {
    this.setNotification(3000, text, imgSrc);
  }

  public showNotificationWithCustomTimeout(text: string, timeout: number, imgSrc?: string): void {
    this.setNotification(timeout, text, imgSrc);
  }

  private setNotification(timeout: number, text: string, imgSrc?: string): void {
    const bodyEl = document.querySelector('body');
    const notificationEl = document.createElement('div');
    const textEl = document.createElement('span');

    if (imgSrc) {
      const imgEl = document.createElement('img');
      imgEl.src = imgSrc;
      imgEl.style.content = imgSrc;

      notificationEl.appendChild(imgEl);
    }

    notificationEl.className = 'notification';
    textEl.innerHTML = this.localizationService.getString(text);

    notificationEl.appendChild(textEl);

    bodyEl?.appendChild(notificationEl);

    setTimeout(() => {
      bodyEl?.removeChild(notificationEl);
    }, timeout);
  }
}
