<div [ngClass]="{ container: true, 'color-black': isHomescreenOrBonusProgram, 'rich-intro': isHomescreenOrBonusProgram, tablet: viewSizeService.isTablet }">
    <div>
        <div class="left">
            <div>
                <a [routerLink]="languageService.createLink('/')">
                    <span>xChange.bg</span>
                </a>
            </div>
        </div>

        <div class="right">
            <ng-container *ngFor="let option of options">
                <div [ngClass]="{ active: option.active }">
                    <span>
                        <a [routerLink]="languageService.createLink(option.path)">
                            {{ option.label | locale }}
                            <app-icon *ngIf="option.icon" [icon]="option.icon"></app-icon>
                        </a>
                    </span>
                    <div class="underline"></div>
                </div>
            </ng-container>
        </div>
    </div>

    <div>
        <app-language-select></app-language-select>

        <app-theme-button></app-theme-button>

        <ng-container *ngIf="isUserLogged">
            <ng-container *ngIf="!isOrder">
                <div class="btn-trade">
                    <app-button class="secondary" [link]="'/order'">{{ "navigation_order" | locale }}</app-button>
                </div>
            </ng-container>

            <div>
                <app-button [link]="'/profile'">{{ "navigation_profile" | locale }}</app-button>
            </div>
        </ng-container>

        <ng-container *ngIf="!isUserLogged">
            <app-button class="secondary" [link]="'/login'">{{ "navigation_login" | locale }}</app-button>
        </ng-container>
    </div>
</div>
