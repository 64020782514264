import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSuffix'
})

export class ThousandSuffixPipe implements PipeTransform {

  private suffixes = ['K', 'M', 'B', 'T'];

  public transform(input: number | undefined, decimals?: number): any {

    if (Number.isNaN(input) || input === undefined) {
      return null;
    }

    if (input < 1000) {
      return input;
    }

    let thousands: number;
    thousands = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, thousands)).toFixed(decimals) + this.suffixes[thousands - 1];
  }

}
