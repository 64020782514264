import { Component, Inject } from '@angular/core';
import { CopyService } from '../../../services/copy.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewSizeService } from '../../../services/view-size.service';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.css'],
})
export class QrCodeDialogComponent {
  public dialogData: any;

  constructor(
    public copyService: CopyService,
    public viewSizeService: ViewSizeService,
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data) {
      this.dialogData = data;
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public downloadQRCode(qrcode: any): void {
    const downloadLink = document.createElement('a');
    const fileName = 'xChange_Qrcode.png';

    downloadLink.href = qrcode;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public copyReferralLink(): void {
    this.copyService.copyToClipboard(this.dialogData.referralLink);
  }

  public shareOnInstagram(): void {
    window.open(`https://www.instagram.com/?url=${this.dialogData.referralLink}`, '_blank')?.focus();
  }
}
