import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { LogoutService } from './../logout.service';
import { StorageService } from './../storage.service';
import { LanguageService } from './../language.service';

export interface CustomResponse<R> {
  status: number;
  data: R;
  error: CustomError;
}

export interface CustomError extends CustomLoginError {
  field: string;
  key: string;
  description: string;
}

export interface HttpOptions {
  headers?: HttpHeaders;
  params?: any;
  observe: 'response';
  responseType: 'json';
}

interface CustomLoginError {
  profileNumber: string;
}

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(
    private httpClient: HttpClient,
    private logoutService: LogoutService,
    private storageService: StorageService,
    private languageService: LanguageService) { }

  // B -> body
  // P -> URL params
  // R -> response

  public get<R>(url: string): Observable<CustomResponse<R>>;
  public get<R, P>(url: string, params: P): Observable<CustomResponse<R>>;
  // tslint:disable-next-line: unified-signatures
  public get<R, P>(url: string, params: P, responseTypeText: boolean): Observable<CustomResponse<R>>;

  public get<R, P>(url: string, params?: P, responseTypeText?: boolean): Observable<CustomResponse<R>> {
    return this.httpClient.get<R>(url, this.getOptions<P>(params, responseTypeText))
    .pipe(
      map((response: HttpResponse<R>) => {
        this.handleToken(response.headers);
        return this.handleSuccess<R>(response);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError<R>(error);
      })
    );
  }

  public getArrayBuffer(url: string): Observable<CustomResponse<ArrayBuffer>> {
    let headers = new HttpHeaders({
      Accept: 'application/octet-stream; application/json',
      'X-Mobile-App': 'website'
    });

    const token = this.storageService.getItem('token');

    if (token) {
      // http headers are immutable and set returns a new header instead of updating the original one
      headers = headers.set('Token', token);
    }

    return this.httpClient.get(url,
      {
        observe: 'response',
        responseType: 'arraybuffer' ,
        headers
      }).pipe(
          map(response => {
            this.handleToken(response.headers);
            return this.handleSuccess<ArrayBuffer>(response);
          }),
          catchError((error: HttpErrorResponse) => {
            return this.handleError<ArrayBuffer>(error);
          })
      );
  }

  public post<B, R>(url: string, body: B): Observable<CustomResponse<R>> {
    return this.httpClient.post<R>(url, body, this.getOptions())
    .pipe(
      map((response: HttpResponse<R>) => {
        this.handleToken(response.headers);
        return this.handleSuccess<R>(response);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError<R>(error);
      })
    );
  }

  public put<B, R>(url: string, body: B): Observable<CustomResponse<R>> {
    return this.httpClient.put<R>(url, body, this.getOptions())
    .pipe(
      map((response: HttpResponse<R>) => {
        this.handleToken(response.headers);
        return this.handleSuccess<R>(response);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError<R>(error);
      })
    );
  }

  public delete<R>(url: string): Observable<CustomResponse<R>> {
    return this.httpClient.delete<R>(url, this.getOptions())
    .pipe(
      map((response: HttpResponse<R>) => {
        this.handleToken(response.headers);
        return this.handleSuccess<R>(response);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError<R>(error);
      })
    );
  }

  private getOptions(): HttpOptions;
  private getOptions<P>(params?: P, responseTypeText?: boolean): HttpOptions;

  private getOptions<P>(params?: P, responseTypeText?: boolean): HttpOptions {
    let options: HttpOptions = {
      observe: 'response' as 'response',
      responseType: responseTypeText ? 'text' as 'json' : 'json' as 'json'
    };

    if (params) {
      options = {
        ...options,
        params
      };
    }

    options.headers = new HttpHeaders({
      'X-Mobile-App': 'website',
      'X-locale': this.languageService.currentLanguage
    });

    const token = this.storageService.getItem('token');

    if (token) {
      options.headers = options.headers.append('Token', token);
    }

    return options;
  }

  private handleSuccess<R>(response: HttpResponse<R>): CustomResponse<R> {
    const customResponse: CustomResponse<R> = {
      status: 200,
      data: response.body ? response.body : { } as R,
      error: { } as CustomError
    };

    return customResponse;
  }

  private handleError<R>(error: HttpErrorResponse): Observable<CustomResponse<R>> {
    // Unauthorized
    if (error.status === 401) {
      this.logoutService.logout();
    }

    // Server Error
    const isServerError = error.status.toString().match('^5') !== null;
    if (isServerError) {
      // show something for server error
    }

    const customResponse: CustomResponse<R> = {
      status: error.status,
      data: { } as R,
      error: error.error ? error.error.errors[0] : null
    };

    return of(customResponse);
  }

  private handleToken(headers: HttpHeaders): void {
    const token = headers.get('Authorization');

    if (token) {
      this.storageService.setItem('token', token);
    }
  }

}
