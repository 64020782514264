import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';

import { ButtonModule } from '../button/button.module';

import { PipesModule } from './../../../pipes/pipes.module';

import { CookieBarComponent } from './cookie-bar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [CookieBarComponent],
  imports: [CommonModule, RouterModule, ButtonModule, PipesModule, MatTabsModule, MatCheckboxModule],
  exports: [CookieBarComponent],
})
export class CookieBarModule {}
