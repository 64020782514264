<div class="cookie-container" *ngIf="showConsent">
    <div class="info">
        <p>
            <span>{{ "cookieBar_description1" | locale }} </span>
            <a [routerLink]="languageService.createLink('/legal/privacy-policy')">{{ "cookieBar_privacyPolicyLink" | locale }}</a>
            <span> {{ "cookieBar_description2" | locale }}</span>
        </p>
        <app-button
            class="empty dark-side"
            (click)="togglePrefs()"
        >
            {{ "cookieBar_preferences" | locale }}
        </app-button>
        <app-button
            class="cta"
            (click)="acceptAll()"
        >
            {{ "cookieBar_consentBtn" | locale }}
        </app-button>
    </div>
    <div class="details" [hidden]="!prefsVisible">
        <mat-tab-group selectedIndex="0">
            <mat-tab label="{{ 'cookieBar_necessary' | locale }}">
                <div [innerHTML]="localizationService.getString('cookieBar_necessaryBody')"></div>
                <p>
          		    <mat-checkbox [checked]="true" [disabled]="true">{{ 'cookieBar_necessary' | locale }}</mat-checkbox>
                </p>
            </mat-tab>
            <mat-tab label="{{ 'cookieBar_marketing' | locale }}">
                <div [innerHTML]="localizationService.getString('cookieBar_marketingBody')"></div>
                <p>
                    <mat-checkbox (change)="checkChange('marketing',$event)" [checked]="consents.marketing">{{ 'cookieBar_marketing' | locale }}</mat-checkbox>
                </p>
            </mat-tab>
            <mat-tab label="{{ 'cookieBar_thirdParty' | locale }}">
                <div [innerHTML]="localizationService.getString('cookieBar_thirdPartyBody')"></div>
                <p>
          		    <mat-checkbox (change)="checkChange('thirdParty',$event)" [checked]="consents.thirdParty">{{ 'cookieBar_thirdParty' | locale }}</mat-checkbox>
                </p>
            </mat-tab>
        </mat-tab-group>
        <p>
            <app-button class="cta" (click)="done()" >
                {{ "global_done" | locale }}
            </app-button>
        </p>
    </div>
</div>
