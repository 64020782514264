export const endpoints = {
  auth: {
    login: 'login',
    register: 'register',
    verifyEmail: 'verify-email',
    resendVerificationEmail: 'resend-verification-email',
    forgottenPassword: 'forgotten-password',
    resetPassword: 'reset-password',
    changePassword: 'change-password',
    TFACodeInfo: 'enable-two-fa',
    activateTFA: 'activate-two-fa',
    deactivateTFA: 'disable-two-fa',
    TFAAuthentication: 'authentication',
    getKYCAccessToken: 'kyc-access-token'
  },
  order: {
    create: 'orders',
    estimate: 'estimate-order',
    paymentGateways: 'payment-gateways',
    exchangePairs: 'exchange-pairs',
    userOrders: 'orders',
    userOrdersCsv: 'orders/csv',
    orderInvoice: 'profile/download-order-invoice'
  },
  user: {
    getLoggedInUserInfo: 'user',
    wallets: 'user-wallets',
    bankAccounts: 'bank-accounts',
    bonuses: 'user-bonuses'
  },
  news: {
    getNews: 'articles?offset={{offset}}&limit={{limit}}',
    getArtlicle: 'articles'
  },
  bonus: {
    estimateBonus: 'estimate-bonus',
    bonusWithdrawal: 'bonus-withdrawal'
  },
  chart: {
    markets: 'coins/markets',
    chartData: 'coins/:id/market_chart'
  },
  phrase: {
    getTranslation: 'v2/projects/{{projectId}}/locales/{{language}}/download?file_format=simple_json&include_empty_translations=true',
    getKeys: 'v2/projects/{{projectId}}/keys'
  }
};
