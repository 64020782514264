import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { LanguageService } from './../../../services/language.service';
import { ViewSizeService } from './../../../services/view-size.service';

interface LanguageSelect {
  [key: string]: {
    img: string;
  };
}

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css'],
})
export class LanguageSelectComponent {
  @ViewChild('languageSelect') languageSelect: ElementRef;

  constructor(
    public languageService: LanguageService,
    public viewSizeService: ViewSizeService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.createLanguageSelect();
  }

  public doShowLanguageList: boolean;

  public selectValues: LanguageSelect = {};

  // Closing language select on click outside of it
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.languageSelect.nativeElement.contains(event.target)) {
      this.doShowLanguageList = false;
    }
  }

  public toggleLanguageList(): void {
    this.doShowLanguageList = !this.doShowLanguageList;
  }

  // TODO: Call this once after navigation. It is now triggered by every change detection
  public getLanguageUrl(languageOption: string): { url: string; query: { [key: string]: string | number } } {
    const url = this.router.url.split('?')[0];
    const query = this.route.snapshot.queryParams;
    if ((url === '/' || url === `/${this.languageService.currentLanguage}`) && languageOption === 'bg') {
      return { url: '/', query };
    }

    if (url.indexOf(this.languageService.currentLanguage) === -1) {
      return { url: url + languageOption.toLowerCase(), query };
    }

    return { url: url.replace(this.languageService.currentLanguage, languageOption).toLowerCase(), query };
  }

  private createLanguageSelect(): void {
    for (const language of this.languageService.availableLanguages) {
      this.selectValues[language] = {
        img: `assets/img/countries/${language}.svg`,
      };
    }
  }
}
