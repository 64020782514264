import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoostPopupComponent } from './boost-popup.component';
import { BoostPopupDialogComponent } from './boost-popup-dialog/boost-popup-dialog.component';

import { IconModule } from '../icon/icon.module';

import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [BoostPopupComponent, BoostPopupDialogComponent],
  imports: [CommonModule, MatDialogModule, IconModule, PipesModule, RouterModule, ButtonModule],
  exports: [BoostPopupComponent, BoostPopupDialogComponent],
})
export class BoostPopupModule {}
