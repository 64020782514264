import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StorageService } from './../../services/storage.service';
import { LanguageService } from './../../services/language.service';
import { ViewSizeService } from './../../services/view-size.service';

export interface NavigationOption {
  label: string;
  path: string;
  active: boolean;
  icon?: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnDestroy {
  constructor(
    public viewSizeService: ViewSizeService,
    private router: Router,
    private storageService: StorageService,
    private languageService: LanguageService,
  ) {
    this.isUserLogged = !!this.storageService.getItem('token');

    this.storageService.token$.pipe(takeUntil(this.unsubscribe)).subscribe((token: string | null) => {
      this.isUserLogged = !!token;
    });

    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.isOrder = this.orderPattern.test(e.url);
        this.isHomescreenOrBonusProgram =
          e.url === '/' || !!e.url.match(this.homePagePattern) || !!e.url.match(this.bonusProgramPattern);
        this.updateActiveOption(e.urlAfterRedirects);
      }
    });
  }

  public isOrder: boolean;
  public isUserLogged: boolean;
  public isHomescreenOrBonusProgram: boolean;

  public navigationOptions: NavigationOption[] = [
    { label: 'Boost', path: '/boost', active: false, icon: 'flame' },
    { label: 'navigation_bonusProgram', path: '/bonus-program', active: false },
    { label: 'navigation_news', path: '/news', active: false },
    { label: 'navigation_faq', path: '/faq', active: false },
  ];

  private homePagePattern = /^\/\w{2}$/g;
  private orderPattern = /^\/\w{2}\/order$/g;
  private bonusProgramPattern = /^\/\w{2}\/bonus-program$/g;

  private unsubscribe = new Subject();

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private updateActiveOption(path: string): void {
    // Remove the language from the path, because its dynamic and is created using createLink in [routerLink]
    path = path.replace('/' + this.languageService.currentLanguage, '');

    for (const option of this.navigationOptions) {
      if (option.path === '' && this.languageService.isDefaultLanguage()) {
        option.active = path === '/';
      } else {
        option.active = path === `${option.path}`;
      }
    }
  }
}
