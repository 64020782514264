<div [ngClass]="{ container: true, 'color-black': isHomescreenOrBonusProgram, 'rich-intro': isHomescreenOrBonusProgram }">
    <div class="left">
        <div>
            <a [routerLink]="languageService.createLink('/')">
                <span>xChange.bg</span>
            </a>
        </div>
    </div>

    <div class="right">
        <ng-container *ngIf="isUserLogged && !isOrder">
            <app-button [link]="'/order'">{{ "navigation_order" | locale }}</app-button>
        </ng-container>

        <ng-container *ngIf="!isUserLogged">
            <app-button class="secondary" [link]="'/login'">{{ "navigation_login" | locale }}</app-button>
        </ng-container>

        <button  (click)="triggerAnimation()"><app-icon icon="menu"></app-icon></button>
    </div>
</div>

<div class="expandable" *ngIf="isExpandable" [@NavigationMobileAnimation]>
    <div class="top" (click)="triggerAnimation()">
        <app-icon icon="close"></app-icon>
        <span>{{ "navigation_closeMobile" | locale }}</span>
    </div>

    <div class="middle">
        <ng-container *ngFor="let option of options">
            <div [ngClass]="{ active: option.active }">
                <span>
                    <a [routerLink]="languageService.createLink(option.path)" (click)="onOptionChange()">
                        {{ option.label | locale }}
                    </a>
                </span>
                <div></div>
            </div>
        </ng-container>
    </div>

    <div class="bottom">
        <app-button class="secondary" *ngIf="!isUserLogged" (onclick)="closeNav('/login')">{{ "navigation_login" | locale }}</app-button>
        <app-button *ngIf="isUserLogged" (onclick)="closeNav('/profile')">{{ "navigation_profile" | locale }}</app-button>

        <div>
            <app-theme-button></app-theme-button>

            <app-language-select></app-language-select>
        </div>
    </div>
</div>
