import { Component, Input } from '@angular/core';

import { ThemeService } from './../../../services/theme.service';
import { LanguageService } from './../../../services/language.service';

import { NavigationMobileAnimation } from './../../../animations/navigation-mobile';

import { NavigationOption } from './../navigation.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.css'],
  animations: [NavigationMobileAnimation],
})
export class NavigationMobileComponent {
  @Input() options: NavigationOption[];
  @Input() isOrder: boolean;
  @Input() isUserLogged: boolean;
  @Input() isHomescreenOrBonusProgram: boolean;

  constructor(public themeService: ThemeService, public languageService: LanguageService, public router: Router) {}

  public isExpandable = false;

  public onOptionChange(): void {
    this.triggerAnimation();
  }

  public triggerAnimation(): void {
    this.isExpandable = !this.isExpandable;
  }
  public closeNav(url: string): void {
    this.isExpandable = false;
    this.router.navigate([this.languageService.createLink(url)]);
  }
}
