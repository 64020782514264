import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { FooterModule } from './../../components/footer/footer.module';
import { NavigationModule } from './../../components/navigation/navigation.module';
import { CookieBarModule } from './../../components/custom/cookie-bar/cookie-bar.module';
import { GlobalBannerModule } from './../../components/global-banner/global-banner.module';

import { LayoutWrapperComponent } from './layout-wrapper.component';

@NgModule({
  declarations: [LayoutWrapperComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    PerfectScrollbarModule,
    FooterModule,
    NavigationModule,
    CookieBarModule,
    GlobalBannerModule,
  ],
  exports: [LayoutWrapperComponent],
})
export class LayoutWrapperModule {}
