import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { SEOService } from './../services/seo.service';

import { LanguageService } from './../services/language.service';

@Injectable()
export class LanguageGuard implements CanActivateChild, CanActivate {
  constructor(private router: Router, private languageService: LanguageService, private seoService: SEOService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | UrlTree {
    // Since at some point anything can be wrapped one level deeper, set a variable mainRoute that points to the correct route
    // i.e route // route.children[0]
    const mainRoute = route.children[0];
    return this.validateLanguageForRoute(mainRoute);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | UrlTree {
    // Since at some point anything can be wrapped one level deeper, set a variable mainRoute that points to the correct route
    // i.e route // route.children[0]
    const mainRoute = route;
    return this.validateLanguageForRoute(mainRoute);
  }

  private validateLanguageForRoute(mainRoute: ActivatedRouteSnapshot): Observable<boolean> | UrlTree {
    const language = mainRoute.params.lang;

    // Check if :lang parameter exist in the route
    if (language) {
      // Check if the language is valid
      if (this.languageService.availableLanguages.indexOf(language) !== -1) {
        const config = mainRoute.routeConfig;

        // Handle 404 route
        if (config && config.path === ':lang') {
          if (mainRoute.children[0].routeConfig?.path === '**') {
            this.seoService.generatePageNotFoundTag();
          }
        }

        this.languageService.setLanguage(language);
        return of(true);
      } else {
        // Handle invalid language by redirecting
        let redirectUrl = `/${this.languageService.defaultLanguage}/` + mainRoute.url.join('/');
        this.languageService.setLanguage(this.languageService.defaultLanguage);

        // since we are in the :lang wildcard case, every other added route is in the child (:lang -> **)
        if (mainRoute.children[0] && mainRoute.children[0].url.length > 0) {
          redirectUrl += '/' + mainRoute.children[0].url.join('/');
        }

        this.seoService.generateRedirectTag(redirectUrl);
        return this.router.parseUrl(redirectUrl);
      }
    } else if (!language && !mainRoute.routeConfig?.path) {
      // Handle home screen default language navigation
      this.languageService.setLanguage(this.languageService.defaultLanguage);
      return of(true);
    }

    // :lang parameter does not exist for Home screen default language route and Nested Child routes.
    // For child routes we always return TRUE because the Parent Guard where the parameter exist has succeeded without redirection
    return of(true);
  }
}
