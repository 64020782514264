import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { KeysResponse, Localization } from './../../../models/localization';

import { env } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalizationRequestService {
  constructor(private httpClient: HttpClient) {}

  // B -> body
  // R -> response

  public getTranslation(language: string): Observable<Localization> {
    const endpoint = env.endpoints.phrase.getTranslation
      .replace('{{projectId}}', env.phrase.projectId)
      .replace('{{language}}', language);
    const url = env.phrase.apiUrl + endpoint;

    return this.httpClient.get<Localization>(url, { headers: this.createHeaders() }).pipe(
      catchError((e) => {
        // If Phrase request is failing, return an empty object. This will display the IDs of every string but still allow app loading.
        // TODO: Maybe load a local json file for default language?
        return of({});
      }),
    );
  }

  public getKeys(): Observable<KeysResponse> {
    const endpoint = env.endpoints.phrase.getKeys.replace('{{projectId}}', env.phrase.projectId);
    const url = env.phrase.apiUrl + endpoint;

    return this.httpClient.get<KeysResponse>(url, { headers: this.createHeaders() });
  }

  private createHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      Authorization: `token ${env.phrase.token}`,
    });

    return headers;
  }
}
