import { NgModule } from '@angular/core';

import { SafePipe } from './safe.pipe';
import { LocalePipe } from './locale.pipe';
import { ThousandSuffixPipe } from './thousand-suffix.pipe';
import { AppDatePipe } from './date.pipe';
import { AppCurrencyPipe, AppDecimalPipe } from './numbers.pipe';

@NgModule({
  declarations: [SafePipe, LocalePipe, ThousandSuffixPipe, AppDatePipe, AppCurrencyPipe, AppDecimalPipe],
  exports: [SafePipe, LocalePipe, ThousandSuffixPipe, AppDatePipe, AppCurrencyPipe, AppDecimalPipe],
})
export class PipesModule {}
