import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ViewSizeService {

  constructor() {
    this.update();
    this.subscribeToWindowResize();
  }

  private onResize = new Subject<null>();
  public onResize$ = this.onResize.asObservable();

  public isMobile: boolean;
  public isTablet: boolean;
  public isMobilePortrait: boolean;

  private mobileBreakPoint = 1270;
  private tabletBreakPoint = 1580;
  private mobilePortraitBreakPoint = 600;

  private subscribeToWindowResize(): void {
    window.onresize = () => {
      this.update();
      this.onResize.next();
    };
  }

  private update(): void {
    this.isMobile = window.innerWidth <= this.mobileBreakPoint;
    this.isTablet = window.innerWidth <= this.tabletBreakPoint && window.innerWidth > this.mobileBreakPoint;
    this.isMobilePortrait = window.innerWidth <= this.mobilePortraitBreakPoint;
  }

}
