import { Component } from '@angular/core';

import { ThemeService, Theme } from '../../../services/theme.service';
import { ViewSizeService } from './../../../services/view-size.service';

@Component({
  selector: 'app-theme-button',
  templateUrl: './theme-button.component.html',
  styleUrls: ['./theme-button.component.css']
})

export class ThemeButtonComponent {

  constructor(
    public themeService: ThemeService,
    public viewSizeService: ViewSizeService) { }

  public currentTheme = this.themeService.currentTheme;

  public updateTheme(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    const theme = eventTarget.checked ? Theme.Dark : Theme.Light;

    this.themeService.setTheme(theme);
  }

}
