<div>
    <div class="wrapper">
        <div class="text">
            <h2>Разбра ли за новата ни услуга? <strong>Boost <app-icon icon="flame"></app-icon></strong></h2>
            <p>Изтегли приложението и започни да печелиш още днес!</p>
            <p class="actions">
                <app-button class="plain" (onclick)="buttonClick('android')">
                    <img src="/assets/img/apps/googleplay.svg" alt="Google Play" />
                </app-button>

                <app-button class="plain" (onclick)="buttonClick('ios')">
                    <img src="/assets/img/apps/appstore.svg" alt="Apple Store" />
                </app-button>
            </p>

            <p class="more">
                <a class="a-link"  (click)="close()" [routerLink]="languageService.createLink('/boost')">Виж повече <app-icon icon="link-arrow"></app-icon></a>
            </p>
        </div>
        <p class="img-wrap">
            <img src="/assets/img/boost/phones_rocket.png" alt="App mock-up" />
        </p>
    </div>
</div>
    <button class="close" (click)="close()">✖</button>
