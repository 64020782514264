import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeButtonComponent } from './theme-button.component';

@NgModule({
  declarations: [ ThemeButtonComponent ],
  imports: [
    CommonModule
  ],
  exports: [ ThemeButtonComponent ]
})

export class ThemeButtonModule { }
