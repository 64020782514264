import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '../services/language.service';

/**
 * Extends the default CurrencyPipe to use the current locale (if not set explicitly).
 */
@Pipe({
  name: 'currency',
})
export class AppCurrencyPipe extends CurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string, private i18n: LanguageService) {
    super(locale);
  }

  /* tslint:disable:unified-signatures */
  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null;
  transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): null;
  transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null;

  transform(
    value: number | string | null | undefined,
    currencyCode: string = 'USD',
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol-narrow',
    digitsInfo?: string,
    locale?: string,
  ): string | null {
    if (this.i18n.currentLanguage) {
      locale = locale || this.i18n.currentLanguage;
    }
    digitsInfo = digitsInfo || '1.2-20';

    return super.transform(value, currencyCode, display, digitsInfo, locale);
  }
}

@Pipe({ name: 'number' })
export class AppDecimalPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string, private i18n: LanguageService) {
    super(locale);
  }

  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null;
  transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    if (this.i18n.currentLanguage) {
      locale = locale || this.i18n.currentLanguage;
    }

    return super.transform(value, digitsInfo, locale);
  }
}
