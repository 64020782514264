import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})

@Injectable({
  providedIn: 'root'
})

export class SafePipe implements PipeTransform {

  constructor(
    private domSanitizer: DomSanitizer) { }

  public transform(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
