import { Injectable } from '@angular/core';

import { ThemeService } from './theme.service';
import { NotificationService } from './notification.service';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  constructor(
    private themeService: ThemeService,
    private notificationService: NotificationService,
    private localization: LocalizationService,
  ) {}

  public copyToClipboard(value: string): void {
    const dummy = document.createElement('textarea');
    dummy.value = value;
    dummy.setAttribute('readonly', '');
    dummy.style.position = 'absolute';
    dummy.style.left = '-9999px';

    document.body.appendChild(dummy);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.notificationService.showNotification(
      this.localization.getString('mesageCopied'),
      this.themeService.imgs[this.themeService.currentTheme].checkIcon,
    );
  }
}
