<div #languageSelect [ngClass]="{ 'language-select': true, mobile: viewSizeService.isMobile }">
    <div (click)="toggleLanguageList()">
        <img matPrefix [src]="selectValues[languageService.currentLanguage]?.img">

        <span>{{ languageService.currentLanguage }}</span>
    </div>

    <ul [ngClass]="{ hidden: !doShowLanguageList }">
        <ng-container *ngFor="let value of selectValues | keyvalue">
            <li [ngClass]="{ selected: languageService.currentLanguage === value.key}" (click)="toggleLanguageList()">
                <a
                    [routerLink]="getLanguageUrl(value.key).url"
                    [queryParams]="getLanguageUrl(value.key).query"
                >
                    <ng-container *ngIf="value.value?.img">
                        <img [src]="value.value?.img">
                    </ng-container>

                    <span>{{ value.key }}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
