import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futureChild = future.children[0] ? future.children[0].children[0] : undefined;
    const currChild = curr.children[0] ? curr.children[0].children[0] : undefined;

    // If the route is the same, but the language parameters has been changed, we force reload
    if (futureChild && currChild && futureChild.params.lang !== currChild.params.lang) {
      return false;
    }

    return future.routeConfig === curr.routeConfig;
  }
}
