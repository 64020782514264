import { Component, Input } from '@angular/core';

import { LanguageService } from './../../../services/language.service';
import { ViewSizeService } from './../../../services/view-size.service';

import { NavigationOption } from './../navigation.component';

@Component({
  selector: 'app-navigation-desktop',
  templateUrl: './navigation-desktop.component.html',
  styleUrls: ['./navigation-desktop.component.css'],
})
export class NavigationDesktopComponent {
  @Input() options: NavigationOption[];
  @Input() isOrder: boolean;
  @Input() isUserLogged: boolean;
  @Input() isHomescreenOrBonusProgram: boolean;

  constructor(public languageService: LanguageService, public viewSizeService: ViewSizeService) {}

}
