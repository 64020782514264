import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { BoostPopupDialogComponent } from './boost-popup-dialog/boost-popup-dialog.component';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-boost-popup',
  templateUrl: './boost-popup.component.html',
  styleUrls: ['./boost-popup.component.css'],
})
export class BoostPopupComponent implements OnInit {
  constructor(private dialog: MatDialog, private cookieService: CookieService) {}

  public ngOnInit(): void {
    if (!this.cookieService.check('boost-popup-shown') && navigator.cookieEnabled) {
      this.cookieService.set('boost-popup-shown', 'true', { expires: 365 });
      this.dialog.open(BoostPopupDialogComponent, {
        panelClass: 'dialog-light',
        autoFocus: false,
        maxHeight: '95vh',
      });
    }
  }
}
