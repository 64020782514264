import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private token = new Subject<string | null>();
  public token$ = this.token.asObservable();

  public getItem(name: string): string | null {
    try {
      return localStorage.getItem(name);
    } catch (err) {
      return null;
    }
  }

  public setItem(name: string, value: string): void {
    try {
      localStorage.setItem(name, value);
    } catch (err) {}

    if (name === 'token') {
      this.emitToken();
    }
  }

  public emitToken(): void {
    this.token.next(this.getItem('token'));
  }
}
