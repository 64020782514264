import { Component } from '@angular/core';

import { SEOService } from './services/seo.service';
import { ThemeService } from './services/theme.service';
import { ScrollBlockService } from './services/scroll-block.service';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import localeBG from './locales/bg';
import { getLocaleCurrencyCode, registerLocaleData } from '@angular/common';
import { LanguageService } from './services/language.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { env } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public iconsSvg: SafeHtml;

  // Init this services at app start
  constructor(
    private seoService: SEOService,
    private scrollBlockService: ScrollBlockService,
    private themeService: ThemeService,
    private http: HttpClient,
    private sanitized: DomSanitizer,
    private language: LanguageService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private cookieService: CookieService,
  ) {
    this.http.get('/assets/img/icons.svg', { responseType: 'text' }).subscribe((svg) => {
      this.iconsSvg = this.sanitized.bypassSecurityTrustHtml(svg.toString().trim());
    });
    registerLocaleData(localeBG, 'bg');
    this.language.languageChange$.subscribe((lang) => {
      try {
        getLocaleCurrencyCode(lang);
      } catch (err) {
        if (err.toString().startsWith('Error: Missing locale data for the locale')) {
          import(`@angular/common/locales/${lang}.js`).then((locale) => this.onLocaleLoaded(lang, locale));
        } else {
          throw err;
        }
      }
    });

    this.router.events.forEach((item) => {
      if (
        item instanceof NavigationEnd &&
        this.cookieService.get('cookiesPolicy') &&
        JSON.parse(this.cookieService.get('cookiesPolicy')).marketing === true
      ) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  private onLocaleLoaded(lang: string, locale?: any): void {
    if (locale) {
      registerLocaleData(locale.default, lang);
    }
  }
}
