import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';

import { Subject } from 'rxjs';

import { LanguageService } from './language.service';
import { LocalizationRequestService } from './http/request/localization-request.service';
import { Localization } from './../models/localization';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor(
    private router: Router,
    private languageService: LanguageService,
    private localizationRequestService: LocalizationRequestService,
  ) {
    // Get the localization each time the language is set
    this.languageService.languageChange$.subscribe((language) => {
      this.getLocalization(language);
    });

    this.router.events.subscribe((e) => {
      // This event is fired right before angular calls the Route Guards
      if (e instanceof ActivationStart) {
        // if the language has been changed, set didLoadLocalization to false in order for the Guards to wait for loading
        if (
          (e.snapshot.routeConfig?.path === '' &&
            !e.snapshot.params.lang &&
            this.languageService.currentLanguage !== this.languageService.defaultLanguage) ||
          (e.snapshot.params.lang && e.snapshot.params.lang !== this.languageService.currentLanguage)
        ) {
          this.didLoadLocalization = false;
        }
      }
    });
  }

  private isLocalizationReady = new Subject<boolean>();
  public isLocalizationReady$ = this.isLocalizationReady.asObservable();

  public didLoadLocalization: boolean;

  private localization: Localization = {};
  private placeholderRegex = /{{(.*?)}}/g;

  public getString(key: string, ...args: (string | number)[]): string {
    // By default return the key
    let result = key;

    if (this.localization[key]) {
      // If we have a localization for that key, set it
      result = this.localization[key];

      // Handle placeholders
      if (args) {
        result = this.handlePlaceholders(result, ...args);
      }
    }

    return result;
  }

  public getLocalization(language: string): void {
    this.localizationRequestService.getTranslation(language).subscribe((response) => {
      this.localization = response;

      this.didLoadLocalization = true;
      this.isLocalizationReady.next(true);
    });
  }

  private handlePlaceholders(result: string, ...args: (string | number)[]): string {
    const placeholders = result.match(this.placeholderRegex);

    for (let i = 0; i < args.length; i++) {
      // Replace every placeholder with a passed parameter only if a placeholder exist
      if (placeholders && placeholders[i]) {
        result = result.replace(placeholders[i], args[i].toString());
      }
    }

    return result;
  }
}
