import { Injectable } from '@angular/core';
import * as Color from 'color';
import { Subject } from 'rxjs';

import { StorageService } from './../services/storage.service';

export enum Theme {
  Dark = 'dark',
  Light = 'light',
}

type ThemeImg = {
  [value in Theme]: {
    [key: string]: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private cssVars: { [key: string]: string };

  constructor(private storageService: StorageService) {
    this.setInitialTheme();
    this.setBranding();
  }

  public currentTheme: Theme;

  private themeChange = new Subject<Theme>();
  public themeChange$ = this.themeChange.asObservable();

  private initialTheme = Theme.Dark;
  private bodyEl = document.querySelector('body') as HTMLBodyElement;

  public imgs: ThemeImg = {
    [Theme.Light]: {
      loaderIcon: './../../assets/img/loader-dark.svg',
      checkIcon: './../../assets/img/check-icon-light.svg',
      settleIcon: '/assets/img/partners/settle-light.svg',
      logoIcon: './../../assets/img/logo-light.svg',
      bankIcon: './../../assets/img/bank-light.svg',
    },
    [Theme.Dark]: {
      loaderIcon: './../../assets/img/loader-light.svg',
      checkIcon: './../../assets/img/check-icon-dark.svg',
      settleIcon: '/assets/img/partners/settle-dark.svg',
      logoIcon: './../../assets/img/logo-dark.svg',
      bankIcon: './../../assets/img/bank-dark.svg',
    },
  };

  public setTheme(theme: Theme): void {
    this.storageService.setItem('theme', theme);
    this.bodyEl.classList.remove('dark', 'light');
    this.bodyEl.classList.add(theme);
    this.currentTheme = theme;

    this.themeChange.next(theme);
  }

  public isDarkTheme(): boolean {
    return this.currentTheme === Theme.Dark;
  }

  public isLightTheme(): boolean {
    return this.currentTheme === Theme.Light;
  }

  private setInitialTheme(): void {
    let storageTheme = this.storageService.getItem('theme') as Theme;

    if (!storageTheme) {
      storageTheme = this.initialTheme;
    }

    this.setTheme(storageTheme);
  }

  private setBranding(): void {
    this.cssVars = { '--primary-branding-color': '#ffc738' };

    if (!this.cssVars['--dark-side-color']) {
      this.cssVars['--dark-side-color'] = (
        getComputedStyle(document.documentElement).getPropertyValue('--dark-side-color') || '#222222'
      ).trim();
    }

    if (!this.cssVars['--dark-side-color--weaker']) {
      this.shiftColor('--dark-side-color', '--dark-side-color--weaker', 0, -0.6048109965635735, 1.108695652173913);
    }

    if (!this.cssVars['--light-side-color']) {
      this.cssVars['--light-side-color'] = (
        getComputedStyle(document.documentElement).getPropertyValue('--light-side-color') || '#ffffff'
      ).trim();
    }

    if (!this.cssVars['--light-side-color--weaker']) {
      this.shiftColor('--light-side-color', '--light-side-color--weaker', 0, 0, -0.047430830039525806);
    }

    if (!this.cssVars['--light-side-color--weaker-2']) {
      this.shiftColor('--light-side-color', '--light-side-color--weaker-2', 0, 0, -0.03952569169960489);
    }

    if (!this.cssVars['--primary-error-color']) {
      this.cssVars['--primary-error-color'] = (
        getComputedStyle(document.documentElement).getPropertyValue('--primary-error-color') || '#e22444'
      ).trim();
    }

    if (this.cssVars['--primary-branding-color']) {
      this.clampLightness('--primary-branding-color');

      if (!this.cssVars['--primary-branding-color--gradient']) {
        this.shiftColor(
          '--primary-branding-color',
          '--primary-branding-color--gradient',
          2.91181937082672,
          -0.09876543209876544,
          0.3294212218649516,
        );
      }

      if (!this.cssVars['--primary-branding-color--lighter']) {
        this.shiftColor(
          '--primary-branding-color',
          '--primary-branding-color--lighter',
          -0.3286926435455939,
          0,
          0.24758842443729895,
        );
      }

      if (!this.cssVars['--primary-branding-color--contrast']) {
        this.setContrastColor('--primary-branding-color');
      }

      if (!this.cssVars['--primary-cta-color']) {
        this.shiftColor('--primary-branding-color', '--primary-cta-color', 180, 0, 0);
        this.clampLightness('--primary-cta-color', 45, 60);
        this.clampSaturation('--primary-cta-color', 50, 80);
      }

      if (!this.cssVars['--primary-cta-color--contrast']) {
        this.setContrastColor('--primary-cta-color');
      }

      if (!this.cssVars['--primary-accent-color']) {
        this.shiftColor(
          '--primary-branding-color',
          '--primary-accent-color',
          129.6360999629017,
          -0.3868312757201646,
          -0.2186495176848875,
        );
      }

      if (!this.cssVars['--primary-accent-color--contrast']) {
        this.setContrastColor('--primary-accent-color');
      }

      if (!this.cssVars['--primary-accent-color--lighter']) {
        this.shiftColor(
          '--primary-accent-color',
          '--primary-accent-color--lighter',
          -0.3286926435455939,
          0,
          0.24758842443729895,
        );
      }
      // END of custom colors

      // A11Y
      this.shiftColor('--primary-branding-color', '--primary-branding-color--on-dark', 0, 0, 0);
      this.adjustMinimumContrast('--primary-branding-color--on-dark', '--dark-side-color');

      this.shiftColor('--primary-cta-color', '--primary-cta-color--on-dark');
      this.adjustMinimumContrast('--primary-cta-color--on-dark', '--dark-side-color');

      this.shiftColor('--primary-accent-color', '--primary-accent-color--on-dark');
      this.adjustMinimumContrast('--primary-accent-color--on-dark', '--dark-side-color');

      this.shiftColor('--primary-branding-color', '--primary-branding-color--on-light');
      this.adjustMinimumContrast('--primary-branding-color--on-light', '--light-side-color');

      this.shiftColor('--primary-cta-color', '--primary-cta-color--on-light');
      this.adjustMinimumContrast('--primary-cta-color--on-light', '--light-side-color');

      this.shiftColor('--primary-accent-color', '--primary-accent-color--on-light');
      this.adjustMinimumContrast('--primary-accent-color--on-light', '--light-side-color');

      this.shiftColor('--primary-error-color', '--primary-error-color--on-light');
      this.shiftColor('--primary-error-color', '--primary-error-color--on-dark');
      this.adjustMinimumContrast('--primary-error-color--on-light', '--light-side-color');
      this.adjustMinimumContrast('--primary-error-color--on-dark', '--dark-side-color');

      Object.keys(this.cssVars).forEach((cssVar) => {
        document.documentElement.style.setProperty(cssVar, this.cssVars[cssVar]);
      });
    }
  }

  private clampLightness(key: string, min = 30, max = 65): void {
    if (Color(this.cssVars[key]).lightness() < min) {
      this.cssVars[key] = Color(this.cssVars[key]).lightness(min).hex();
    } else if (Color(this.cssVars[key]).lightness() > max) {
      this.cssVars[key] = Color(this.cssVars[key]).lightness(max).hex();
    }
  }

  private clampSaturation(key: string, min = 30, max = 65): void {
    if (Color(this.cssVars[key]).saturationl() < min) {
      this.cssVars[key] = Color(this.cssVars[key]).saturationl(min).hex();
    } else if (Color(this.cssVars[key]).saturationl() > max) {
      this.cssVars[key] = Color(this.cssVars[key]).saturationl(max).hex();
    }
  }

  private adjustMinimumContrast(key: string, reference: string, minContrast = 6): void {
    if (Color(this.cssVars[reference]).lightness() < Color(this.cssVars[key]).lightness()) {
      while (Color(this.cssVars[reference]).contrast(Color(this.cssVars[key])) < minContrast) {
        this.cssVars[key] = Color(this.cssVars[key]).lighten(0.05).hex();
      }
    } else {
      while (Color(this.cssVars[reference]).contrast(Color(this.cssVars[key])) < minContrast) {
        this.cssVars[key] = Color(this.cssVars[key]).darken(0.05).hex();
      }
    }
  }

  public colorDiff(src: string, dest: string): number[] {
    const colsA = (Color(dest).hsl() as any).color;
    const colsB = (Color(src).hsl() as any).color;
    return [colsA[0] - colsB[0], (colsA[1] - colsB[1]) / colsB[1], (colsA[2] - colsB[2]) / colsB[2]];
  }

  public setContrastColor(key: string, minContrast = 4.5): void {
    if (Color(this.cssVars[key]).contrast(Color(this.cssVars['--dark-side-color'])) >= minContrast) {
      this.cssVars[key + '--contrast'] = this.cssVars['--dark-side-color'];
    } else {
      this.cssVars[key + '--contrast'] = this.cssVars['--light-side-color'];
    }
  }

  public shiftColor(
    input: string,
    output: string,
    rotate: number = 0,
    saturate: number = 0,
    lighten: number = 0,
  ): void {
    this.cssVars[output] = Color(this.cssVars[input]).rotate(rotate).saturate(saturate).lighten(lighten).hex();
  }
}
