<button mat-flat-button
    *ngIf="!isLinkBtn"
    class="button"
    [ngClass]="isConfirmBtn ? 'confirmBtn' : ''"
    [disabled]="disabled"
    (click)="onClickFn()"
>
    <span class="content">
        <app-icon *ngIf="icon" [icon]="icon"></app-icon>
        <span>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </span>
    </span>
</button>
<a mat-flat-button
    class="button"
    *ngIf="isLinkBtn && href"
    [attr.target]="target"
    [attr.href]="href"
    [ngClass]="{ 'mat-button-disabled': disabled }">
    <span class="content">
        <app-icon *ngIf="icon" [icon]="icon"></app-icon>
        <span>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </span>
    </span>
</a>
<a mat-flat-button
    class="button"
    *ngIf="isLinkBtn && !href"
    [ngClass]="{ 'mat-button-disabled': disabled }"
    [routerLink]="disabled ? '#' : languageService.createLink(link)">
    <span class="content">
        <app-icon *ngIf="icon" [icon]="icon"></app-icon>
        <span>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </span>
    </span>
</a>

<ng-template #content><ng-content></ng-content></ng-template>
