import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EmailService {

  public openEmail(mailTo: string, subject?: string): void {
    if (!subject) {
        window.location.href = `mailto:${mailTo}`;
        return;
    }

    subject = subject.replace(/\s+/, '%20');
    window.location.href = `mailto:${mailTo}?subject=${subject}`;
  }
}
