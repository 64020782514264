import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-boost-popup-dialog',
  templateUrl: './boost-popup-dialog.component.html',
  styleUrls: ['./boost-popup-dialog.component.css'],
})
export class BoostPopupDialogComponent implements OnInit {
  public data: {};

  constructor(
    private dialogRef: MatDialogRef<any, any>,
    @Inject(MAT_DIALOG_DATA) data: {},
    public languageService: LanguageService,
  ) {
    this.data = data;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public buttonClick(type: string): void {
    switch (type) {
      case 'android':
        window.open('https://play.google.com/store/apps/details?id=bg.xchange', '_blank');
        break;
      case 'ios':
        window.open('https://apps.apple.com/bg/app/xchange-bg/id1543781417?itsct=apps_box_link&itscg=30200', '_blank');
        break;
    }
  }

  ngOnInit(): void {}
}
