import { Component } from '@angular/core';

import { ViewSizeService } from './../../services/view-size.service';
import { GlobalBannerService } from './../../services/global-banner.service';

@Component({
  selector: 'app-global-banner',
  templateUrl: './global-banner.component.html',
  styleUrls: ['./global-banner.component.css']
})

export class GlobalBannerComponent {

  // Setup cookie name in Global Banner Service
  // Add your banner img in Global Banner Component Template
  // Uncomment app-global-banner in app.html
  constructor(
    public viewSizeService: ViewSizeService,
    public globalBannerService: GlobalBannerService) { }

}
