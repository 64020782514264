import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { LanguageSelectComponent } from './language-select.component';

@NgModule({
  declarations: [LanguageSelectComponent],
  imports: [CommonModule, RouterModule],
  exports: [LanguageSelectComponent],
})
export class LanguageSelectModule {}
