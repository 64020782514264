import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatSelectModule } from '@angular/material/select';

import { PipesModule } from './../../../pipes/pipes.module';
import { ButtonModule } from './../../custom/button/button.module';
import { ThemeButtonModule } from '../../custom/theme-button/theme-button.module';
import { IconModule } from '../../custom/icon/icon.module';
import { LanguageSelectModule } from './../../custom/language-select/language-select.module';

import { NavigationMobileComponent } from './navigation-mobile.component';

@NgModule({
  declarations: [NavigationMobileComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatSelectModule,
    ButtonModule,
    ThemeButtonModule,
    IconModule,
    PipesModule,
    LanguageSelectModule,
  ],
  exports: [NavigationMobileComponent],
})
export class NavigationMobileModule {}
