import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LanguageService } from './../../../services/language.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit {
  @Input()
  public link: string;
  @Input()
  public disabled: boolean;

  @Input()
  public confirmBtn: boolean;

  @Input()
  public target: string;

  @Input()
  public href: string;

  @Input()
  public icon: string;

  @Output()
  public onclick = new EventEmitter<void>();

  constructor(public languageService: LanguageService) {}

  public isLinkBtn: boolean;
  public isConfirmBtn: boolean;

  public ngOnInit(): void {
    this.isLinkBtn = !!(this.link || this.href);
    this.isConfirmBtn = this.confirmBtn;
  }

  public onClickFn(): void {
    if (this.disabled) {
      return;
    }

    this.onclick.emit();
  }
}
