import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';

import { Observable } from 'rxjs';

import { LocalizationService } from './../services/localization.service';

@Injectable()
export class LocalizationGuard implements CanActivateChild, CanActivate {
  constructor(private localizationService: LocalizationService) {}

  public canActivate(): Observable<boolean> | boolean {
    return this.isLocalizationLoaded();
  }

  public canActivateChild(): Observable<boolean> | boolean {
    return this.isLocalizationLoaded();
  }

  private isLocalizationLoaded(): Observable<boolean> | boolean {
    if (this.localizationService.didLoadLocalization) {
      return true;
    }

    return this.localizationService.isLocalizationReady$;
  }
}
