import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { LanguageService } from './language.service';
import { LocalizationService } from './localization.service';

export interface Config {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
}

interface MandatoryConfig {
  title: string;
  description: string;
  image: string;
  keywords?: string;
}

interface Hreflang {
  [key: string]: HTMLLinkElement;
}

enum UrlType {
  PREV,
  NEXT,
}

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private title: Title,
    private router: Router,
    private meta: Meta,
    private languageService: LanguageService,
    private localizationService: LocalizationService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.removeCannonicalLink();
      }

      if (event instanceof NavigationEnd) {
        this.generateHreflang();
      }
    });
  }

  private cannonicalLink: HTMLLinkElement;
  private prevLink: HTMLLinkElement;
  private nextLink: HTMLLinkElement;
  private hreflangs: Hreflang = {};

  public generateTags(config?: Config): void {
    const finalConfig = this.createFinalConfig(config);

    this.title.setTitle(finalConfig.title);
    this.meta.updateTag({ name: 'description', content: finalConfig.description });

    if (finalConfig.keywords) {
      this.meta.updateTag({ name: 'keywords', content: finalConfig.keywords });
    }

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@content' });

    this.meta.updateTag({ name: 'twitter:title', content: finalConfig.title });
    this.meta.updateTag({ name: 'twitter:description', content: finalConfig.description });
    this.meta.updateTag({ name: 'twitter:image', content: finalConfig.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'xChange.bg' });

    this.meta.updateTag({ property: 'og:title', content: finalConfig.title });
    this.meta.updateTag({ property: 'og:description', content: finalConfig.description });
    this.meta.updateTag({ property: 'og:image', content: finalConfig.image });
    this.meta.updateTag({ property: 'og:url', content: this.dom.URL });
  }

  public generateCannonicalLink(path?: string): void {
    const canURL = path
      ? this.dom.baseURI + this.languageService.createLink(path).substr(1)
      : this.dom.URL.split('?')[0];

    if (this.cannonicalLink) {
      this.dom.head.removeChild(this.cannonicalLink);
    }

    this.cannonicalLink = this.dom.createElement('link');
    this.cannonicalLink.setAttribute('rel', 'canonical');
    this.cannonicalLink.setAttribute('href', canURL);

    this.dom.head.appendChild(this.cannonicalLink);
  }

  public generatePrevAndNext(
    path: string,
    currentPage: number,
    isLastPage?: boolean,
    queryParams?: { key: string; value: string }[],
  ): void {
    this.removePrevAndNext();

    if (currentPage !== 1) {
      const prevUrl = this.createPrevNextUrl(UrlType.PREV, path, currentPage, queryParams);

      this.prevLink = this.dom.createElement('link');
      this.prevLink.setAttribute('rel', 'prev');
      this.prevLink.setAttribute('href', prevUrl);

      this.dom.head.appendChild(this.prevLink);
    }

    if (!isLastPage) {
      const nextUrl = this.createPrevNextUrl(UrlType.NEXT, path, currentPage, queryParams);

      this.nextLink = this.dom.createElement('link');
      this.nextLink.setAttribute('rel', 'next');
      this.nextLink.setAttribute('href', nextUrl);

      this.dom.head.appendChild(this.nextLink);
    }
  }

  public removePrevAndNext(): void {
    if (this.prevLink) {
      this.dom.head.removeChild(this.prevLink);
      this.prevLink = null as any;
    }

    if (this.nextLink) {
      this.dom.head.removeChild(this.nextLink);
      this.nextLink = null as any;
    }
  }

  public generateNoindex(): void {
    if (this.meta.getTag('name="robots"')) {
      this.meta.removeTag('name="robots"');
    }

    this.meta.updateTag({ name: 'robots', content: 'noindex' });
  }

  public removeNoindex(): void {
    if (this.meta.getTag('name="robots"')) {
      this.meta.removeTag('name="robots"');
    }
  }

  // Prerender IO status tags
  public generatePageNotFoundTag(): void {
    if (!this.meta.getTag('name="prerender-status-code"')) {
      this.meta.updateTag({ name: 'prerender-status-code', content: '404' });
    }
  }

  public generateRedirectTag(url: string): void {
    this.meta.updateTag({ name: 'prerender-status-code', content: '301' });
    this.meta.updateTag({ name: 'prerender-header', content: `Location: ${this.dom.baseURI}${url.substr(1)}` });

    // This is left for testing purpose
    // this.meta.updateTag({ name: 'prerender-header', content: `Location: ${this.dom.baseURI}${url.substr(1)}?_escaped_fragment_=`});
  }

  public removePrerenderTags(): void {
    this.meta.removeTag('name="prerender-status-code"');
    this.meta.removeTag('name="prerender-header"');
  }

  private generateHreflang(): void {
    const path = this.router.url.slice(1, this.router.url.length);

    // TODO: Change the <html> tag 'lang' attribute according to the current language. Do we do it here, or in the LanguageService?
    // For every supported language, create a Link tag with the correct 'hreflang' attribute
    for (const language of this.languageService.availableLanguages) {
      let href = this.dom.baseURI;

      // If we are on Home page with default language or any other language
      if (path.indexOf(this.languageService.currentLanguage) === -1 || path === this.languageService.currentLanguage) {
        href += language === this.languageService.defaultLanguage ? '' : language;
      } else {
        href += path.replace(this.languageService.currentLanguage, language);
      }

      // Create hreflang element and reference
      if (!this.hreflangs[language]) {
        const hreflang = this.dom.createElement('link');
        hreflang.setAttribute('rel', 'alternate');
        hreflang.setAttribute('hreflang', language);
        hreflang.setAttribute('href', href);

        this.hreflangs[language] = hreflang;
        this.dom.head.appendChild(hreflang);
      } else {
        // Override existing tags, since the only dynamic attribute is the 'href'
        this.hreflangs[language].setAttribute('href', href);
      }
    }
  }

  private removeCannonicalLink(): void {
    if (this.cannonicalLink) {
      this.dom.head.removeChild(this.cannonicalLink);
      this.cannonicalLink = null as any;
    }
  }

  private createPrevNextUrl(
    urlType: UrlType,
    path: string,
    currentPage: number,
    queryParams?: { key: string; value: string }[],
  ): string {
    let url = this.dom.baseURI + this.languageService.createLink(path).substr(1);

    if (urlType === UrlType.PREV) {
      if (queryParams && queryParams.length > 0) {
        url = this.appendQueryParams(url, queryParams);

        if (currentPage - 1 > 1) {
          url += `&page=${currentPage - 1}`;
        }
      } else if (currentPage - 1 > 1) {
        url += `?page=${currentPage - 1}`;
      }

      return url;
    }

    if (queryParams && queryParams.length > 0) {
      url = this.appendQueryParams(url, queryParams);
      url += `&page=${currentPage + 1}`;
    } else {
      url += `?page=${currentPage + 1}`;
    }

    return url;
  }

  private appendQueryParams(url: string, queryParams: { key: string; value: string }[]): string {
    let result = url;

    for (let i = 0; i < queryParams.length; i++) {
      if (i === 0) {
        result += `?${queryParams[i].key}=${queryParams[i].value}`;
        continue;
      }

      result += `&${queryParams[i].key}=${queryParams[i].value}`;
    }

    return result;
  }

  private createFinalConfig(config?: Config): MandatoryConfig {
    const finalConfig: MandatoryConfig = {
      title: this.localizationService.getString(config?.title ? config.title : 'seo_meta_defaultTitle'),
      description: this.localizationService.getString(
        config?.description ? config.description : 'seo_meta_defaultDescription',
      ),
      image: config?.image ? config.image : this.dom.baseURI + 'assets/img/og.png',
    };

    if (config?.keywords) {
      finalConfig.keywords = this.localizationService.getString(config.keywords);
    }

    return finalConfig;
  }
}
