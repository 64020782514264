import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  public currentLanguage: string;
  public defaultLanguage = 'bg';
  public availableLanguages = ['bg', 'en'];

  private languageChange = new Subject<string>();
  public languageChange$ = this.languageChange.asObservable();

  public setLanguage(lang: string): void {
    if (this.currentLanguage !== lang) {
      this.document.documentElement.lang = lang;
      this.currentLanguage = lang;
      this.languageChange.next(lang);
    }
  }

  public createLink(path: string): string {
    if (path === '/' || path === '') {
      // Handle homepage
      if (this.isDefaultLanguage()) {
        return path;
      }

      return `/${this.currentLanguage}`;
    }

    // Handle all cases different from homepage
    return `/${this.currentLanguage}/${path}`;
  }

  public isDefaultLanguage(): boolean {
    return this.currentLanguage === this.defaultLanguage;
  }
}
